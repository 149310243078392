import React from 'react'
import './info-tooltip.scss'

const InfoTooltip = (props) =>
<div className={`info-tooltip ${props.class || ''}`}>
    {props.data}
    <div className={`info-tooltip__data ${props.dataclass || ''}`}>
        <p className={`info-tooltip__tooltext`}>{props.tooltip}</p>
    </div>
</div>

export default InfoTooltip
