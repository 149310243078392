import { loginTypeKey } from 'common-components-spa'

const hostName = `${window.location.origin}`;

export function useAction() {
    const handleRedirectToWrapper = (loginType) => {
        sessionStorage.setItem(loginTypeKey, loginType);
        const redirectUrl = (process.env.NODE_ENV === 'local') ? `${hostName}/spa/wrapper/` : hostName;
        window.location.assign(redirectUrl);
    }

    return {
        handleRedirectToWrapper
    }
}