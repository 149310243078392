import "./NoActiveHubAccount.scss";

const resetPwdLinks = {
  "stg-gb.ggw.ift.pearson-intl.com": "https://login-stg.pearson.com/v1/piapi-int/iesui/forgot-unpw?client_id=SFgLz0MQvoVWwJjwvQ6nBwVjUGmVK3aP&redirect_uri=https%3A%2F%2Fstg-gb.ggw.ift.pearson-intl.com%2F%23%2Fspa%2Fwrapper%2Fapplication&nonce=123454321&prompt=login&lang=en&login_success_url=https%3A%2F%2Fstg-gb.ggw.ift.pearson-intl.com%2F%3FiesCode%3DqQi5sBhkDW%23%2Fspa%2Fwrapper%2Fapplication",
  "qa-gb.ggw.ift.pearson-intl.com": "https://login-stg.pearson.com/v1/piapi-int/iesui/forgot-unpw?client_id=SFgLz0MQvoVWwJjwvQ6nBwVjUGmVK3aP&redirect_uri=https%3A%2F%2Fqa-gb.ggw.ift.pearson-intl.com%2F%23%2Fspa%2Fwrapper%2Fapplication&nonce=123454321&prompt=login&lang=en&login_success_url=https%3A%2F%2Fqa-gb.ggw.ift.pearson-intl.com%2F%3FiesCode%3D8kY0LyCHa5%23%2Fspa%2Fwrapper%2Fapplication",
  "dev-gb.ggw.ift.pearson-intl.com": "https://login-qa.pearson.com/v1/piapi-test/piui/forgot-unpw?client_id=SFgLz0MQvoVWwJjwvQ6nBwVjUGmVK3aP&login_success_url=https:%2F%2Fdev-gb.ggw.ift.pearson-intl.com%2F%3FiesCode%3DCKqmcpPKbj%23%2Fspa%2Fwrapper%2Fapplication&lang=en",
  "homepage.pearson.com": "https://login.pearson.com/v1/piapi/piui/forgot-unpw?client_id=U5AIM36Jb6aNU0MrNG51c9vuQbp0sZ9w&login_success_url=https:%2F%2Fhomepage.pearson.com%2F%3FiesCode%3DDD24hVXoQt%23%2Fspa%2Fwrapper%2Fapplication&lang=en",
  "ggw-gb.pearson.com": "https://login.pearson.com/v1/piapi/piui/forgot-unpw?client_id=U5AIM36Jb6aNU0MrNG51c9vuQbp0sZ9w&login_success_url=https:%2F%2Fggw-gb.pearson.com%2F%3FiesCode%3D6wMtj1QlEM%23%2Fspa%2Fwrapper%2Fapplication&lang=en",
  "gateway.pearson.com": "https://login-stg.pearson.com/v1/piapi-int/iesui/forgot-unpw?client_id=4rdaaSKTN6xgVqeRAXJLWQc9DePEBbMA&redirect_uri=http%3A%2F%2Fgateway.pearson.com%3A3009%2F%23%2Fapplication&nonce=123454321&prompt=login&lang=en&login_success_url=http%3A%2F%2Fgateway.pearson.com%3A3009%2F%3FiesCode%3DIaMVrn2cHQ%23%2Fapplication"
}

const NoActiveHubAccount = () => {
  const pwdResetLink = resetPwdLinks[window.location.hostname];
  
  return (
    <article className="no-activehub-account-error">
      <img src={`${process.env.PUBLIC_URL}/images/error-sorry.svg`} alt="" />
      <h1>Account not set up for ActiveHub</h1>
      <p>
        Do you have more than one Pearson account? We recommend the following:
      </p>
      <ul>
        <li>Check if auto-fill has populated the correct ActiveHub username.</li>
        <li>
          View all your Pearson accounts by{" "}
          <a className="reset-password" href={pwdResetLink} target="_blank" rel="noreferrer">
            requesting a password reset email
          </a>.
        </li>
        <li>Ensure you are signed out of other Pearson websites.</li>
      </ul>
      <p>
        If you are still experiencing difficulties, please contact our support
        team by clicking on the 'Report an issue' button.
      </p>
      <footer className="p-1">
        <a
          className={`button__v2 button__v2__primary p-2`}
          href="https://www.pearsonschoolsandfecolleges.co.uk/activehub/report-an-issue-on-activehub"
          target="_blank"
          rel="noreferrer"
        >
          Report an issue
        </a>
      </footer>
    </article>
  );
};

export default NoActiveHubAccount;
