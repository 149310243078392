import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Input,
  Select,
  Button,
  useValidation,
  Loader,
} from "common-components-spa";
import { AppContext } from "src/App";
import { serviceError } from '../../constants/config';
import UploadPic from "../../components/UploadPic/UploadPic";
import { updateUserProfile, getUserRole, getUserProfile } from "src/network/ApiService";
import { AHPMRIDS } from '../../constants/appConstant'

// css for user profile
import "./UserProfile.scss";

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return (s.toLowerCase()).charAt(0).toUpperCase() + (s.toLowerCase()).slice(1);
}

const UserProfile = ({
  handleAPIError,
  popupConfig: { toastMessage, setLoaderState, setConfirmedVisible },
  isActiveHub
}) => {
  const { region, userId,
    iesSession,
    setUserName,
    entities,
    setSelectedSpaId,
  } = useContext(AppContext);
  const userProfile = window?.USERPROFILE;
  // initial user object
  const initUser = {
    firstName: userProfile?.firstName,
    lastName: userProfile?.lastName,
    emailId: userProfile?.email,
    roles: "",
    organization: "",
  };

  const [userData, setUserData] = useState(initUser);
  const [initailState, setInitailState] = useState(initUser);
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    if (!region) return;
    setLoading(true);

    // Getting the ROLE of user
    const subscription = getUserRole({
      filter: {
        userId,
        regionId: region,
      },
    }).subscribe(
      (response) => {
        const responseData = response?.data || [];
        if("urn:pearson:gps:region:gb" === region){
         let  filteredOrgResponse = responseData[0]?.organizations?.filter(org=> org.productModelRegion.some(r=> AHPMRIDS.includes(r)));
         let  filteredRoles = responseData[0]?.roles?.filter(role=> AHPMRIDS.includes(role.productModelRegionId));
         responseData[0].organizations = filteredOrgResponse
         responseData[0].roles = filteredRoles      
        }
        if (responseData && responseData[0]) {
          let roles = Array.prototype.map.call(
            responseData[0]?.roles,
            function (item) {
              if (
                (responseData[0]?.organizations && responseData[0]?.organizations[0]?.orgId === item.organizationId) ||
                (responseData[0]?.organizationGroups && responseData[0]?.organizationGroups[0]?.orgGroupId === item.organizationGroupId)
              ) {
                return item.role;
              }
            }
          );

          roles = roles.filter((n) => n);
          roles = roles.map(n => {
            if(isActiveHub) {
              if(n.toUpperCase() === "STUDENT") return capitalize('LEARNER');
              if(n.toUpperCase() === "INSTRUCTOR") return capitalize('STAFF');
              if(n.toUpperCase() === "MAT ADMIN") return "MAT Admin";
            }
            return capitalize(n.toUpperCase());
          }).join(', ');
          // setting to state
          setUserData({
            ...userData,
            organization: responseData[0]?.organizations ? responseData[0]?.organizations[0] : {},
            organizationGroup: responseData[0]?.organizationGroups ? responseData[0]?.organizationGroups[0] : {},
            roles: roles,
          });
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );

    return () => subscription.unsubscribe();
    // dont need to run this effect when setters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // validate object
  const isValid = useValidation(userData, ["firstName", "lastName", "emailId"]);

  // handle input change
  const handleInput = (e) => {
    const {
      target: { value, name },
    } = e;

    // setting to state
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const checkChange = useCallback(() => {
    const { firstName, lastName, emailId } = userData;

    if (
      !isValid ||
      (firstName === initailState?.firstName &&
        lastName === initailState?.lastName &&
        emailId === initailState?.emailId)
    ) {
      // checking if form is not valid &&
      // if value is not change
      return false;
    }

    return true;
  }, [userData, initailState, isValid]);


  // form submit
  const updateUser = () => {
    if (isValid) {
      setLoaderState(true);

      updateUserProfile(userProfile?.userId, { emailId: userData.emailId, firstName: userData.firstName, lastName: userData.lastName }).subscribe(
        (response) => {
          console.log("user updated", response);
          toastMessage({
            msg: "<strong>User</strong> updated Successfully !!!",
            success: true,
          });
          // setting the updated state to initail
          getUserProfile(userId)
      .subscribe(
        res => {
          if (!res) {
            console.log('error in api', 'no data in user api');
            return;
          }

          const givenName = res.firstName;
          const familyName = res.lastName;
          let firstInitial = givenName && givenName.length > 0 &&
            givenName[0].toUpperCase();
          let familyInitial = familyName && familyName.length > 0 &&
            familyName[0].toUpperCase();
          setUserName(
            `${firstInitial || ''}${familyInitial || ''}`,
          );
          window.USERPROFILE = { ...res }
        },
        error => {
          if (error && error.data && error.data.status >= 500) {
            setSelectedSpaId(serviceError)
            setUserName('-')
          } else if (error && error.data && error.data.status === 401) {
            iesSession.logout();
          } else if (error && error.data && error.data.status === 404) {
            setUserName('-')
          }
          console.log('error in api', error)
        },
      );
          setInitailState(userData);
        },
        (error) => {
          setLoaderState(false);
          handleAPIError({
            error,
            callback: updateUser,
          });
        },
        () => {
          setLoaderState(false);
          setSelectedSpaId('urn:pearson:gps:spa:landingspaid');
        }
      );
    }
  };
  const {
    firstName,
    lastName,
    emailId,
    roles,
    organization: { orgId, orgName },
  } = userData;

  if (isloading) {
    return <Loader />;
  }

  return (
    <>
      <div className="instructor__head">
        <div className="d-flex -instrHeadAlignment">
          <div>
            <h1 className="instructor__head__txt">{ isActiveHub ? "My account" : "User profile"  }</h1>
          </div>
          <div>
            <Button
              value="Cancel"
              onClick={() => setConfirmedVisible(true)}
              className="button--v2__bordered"
            />
            <Button
              value="Update"
              className={`button__v2 ripple--v2 button__v2__primary__bordered minWidth-100 ${checkChange() || "disable"}`}
              onClick={updateUser}
              tabIndex={!checkChange()?'-1':'0'}
              isDisabled={!checkChange()}
              useButtonClass={false}
            />
          </div>
        </div>
      </div>
      <div className="instructor__body">
        <div className="user-profile d-flex">
          { !isActiveHub &&
            <>
              <div className="col-5">
                <UploadPic
                  onClick={() =>
                    toastMessage({
                      msg: "This feature is temporary disabled",
                      success: false,
                    })
                  }
                />
              </div>
              <div className="vl"></div>
            </>
          }
          <div className={`col-${isActiveHub ? '11' : '7'} pl-5`}>
            <Input
              inputtype={"text"}
              title={"Username"}
              name={"username"}
              value={userProfile?.userName}
              customDataAttributes={{
                "data-id": "username",
                "data-type": "value"
              }}
              isReadonly={true}
              inputClassName={"disabled"}
            />
            <Input
              inputtype={"text"}
              title={"Email address"}
              name="emailId"
              value={entities.decode(emailId)}
              placeholder={"Email"}
              onChange={handleInput}
              customDataAttributes={
                {
                    "autocomplete": "E-mail address"
                }
              }
            />

            <Input
              inputtype={"text"}
              title={"First name"}
              name="firstName"
              value={entities.decode(entities.decode(firstName))}
              placeholder={"First name"}
              onChange={handleInput}
              customDataAttributes={
                {
                    "autocomplete": "given-name"
                }
              }
            />
            <Input
              inputtype={"text"}
              title={"Last name"}
              name="lastName"
              value={entities.decode(entities.decode(lastName))}
              placeholder={"Last name"}
              onChange={handleInput}
              customDataAttributes={
                {
                    "autocomplete": "family-name"
                }
              }
            />

            <Select
              title={"School/Centre"}
              name={"Organisation"}
              options={[
                {
                  value: orgId,
                  label: orgName,
                },
              ]}
              value={orgId}
              customDataAttributes={{
                "data-id": "Organisation",
                "data-type": "value",
                disabled: true,
              }}
            />
            <Select
              title={"Role"}
              name={"role"}
              options={[
                {
                  value: roles,
                  label: roles,
                },
              ]}
              value={roles}
              customDataAttributes={{
                "data-id": "role",
                "data-type": "value",
                disabled: true,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// noop funtion
const noop = () => { };

UserProfile.defaultProps = {
  handleAPIError: noop,
  popupConfig: {
    toastMessage: noop,
    setLoaderState: noop,
    setConfirmedVisible: noop,
  },
};

export default UserProfile;
