import { NetworkOps } from 'common-components-spa';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

export const userLocale = navigator.language || 'en-US';

export const rootAPI = new NetworkOps({
    baseURL: '/api'
});

export const proxyAPI = new NetworkOps({
    baseURL: '/api/proxy/v1'
});

export const proxyAPIv2 = new NetworkOps({
    baseURL: '/api/proxy/v2'
});

export const gatewayConfigurationApi = new NetworkOps({
    baseURL: '/api/configuration/v1',
});

export const permissionAPI = new NetworkOps({
    baseURL: '/api/permission/v1'
});

export const userAbilityAPI = new NetworkOps({
    baseURL: '/api'
});

export const accessCodeApi = new NetworkOps({
    baseURL: "/api/entitlementaccesscodes/v1"
})

export const userPreferenceAPI = new NetworkOps({
    baseURL: '/api/preference/v1'
});

export const organizationAPI = new NetworkOps({
    baseURL: '/api/organization/v1'
});

/* whenever a new networkOps is defined add it in setAuthToken and setUserIdHeader */

export function setAuthToken(token) {
    rootAPI.setAuthToken(token);
    proxyAPI.setAuthToken(token);
    proxyAPIv2.setAuthToken(token);
    gatewayConfigurationApi.setAuthToken(token);
    permissionAPI.setAuthToken(token);
    userAbilityAPI.setAuthToken(token);
    accessCodeApi.setAuthToken(token);
    userPreferenceAPI.setAuthToken(token);
    organizationAPI.setAuthToken(token);
}

export function setUserIdHeader(id) {
    rootAPI.setUserId(id);
    proxyAPI.setUserId(id);
    proxyAPIv2.setUserId(id);
    gatewayConfigurationApi.setUserId(id);
    permissionAPI.setUserId(id);
    userAbilityAPI.setUserId(id);
    accessCodeApi.setUserId(id);
    userPreferenceAPI.setUserId(id);
    organizationAPI.setUserId(id);
}

export function getUserProfile(userId) {
    return userAbilityAPI.get(`/user/v1/user/${userId}`);
}

/**
 * Update the user profile
 * @param userId 
 * @param data 
 */
export function updateUserProfile(userId, data) {
    return userAbilityAPI
        .patch(`/user/v1/user/${userId}/profile`, data);
}

/**
 * Getting the Role of the user
 * @param data 
 * @param token 
 */
export function getUserRole(data) {
    return userAbilityAPI
        .post('/usersearch/v2/search/users', data);
}

export function getFooterLinks(region) {
    return gatewayConfigurationApi.get(
        `/configuration/region/${region}/footerLinks`).pipe(
            catchError(err =>
                err && err.data && (err.data.status === 404 || err.data.status === 402) ? of([]) : throwError(err)
            )
        );
}

export function getHeaderInfo() {
    return gatewayConfigurationApi.get(
        `/configuration/findByHostname/${window.location.host}/locale/${userLocale}`);
}

export function getSpas(region, locale = userLocale) {
    return gatewayConfigurationApi.get(
        `/configuration/region/${region}/locales/${locale}/spas`);
}

export function getSPAsInRegion(region, locale = userLocale) {
    return proxyAPI.get(`/getSPAInRegion/${region}/locale/${locale}`)
}

export function getListofTranslation() {
    return userAbilityAPI.get(`/getTranslationList`);
}

export function getUserAbilities(userId, regionId) {
    return proxyAPI.get(`/getUserAbilityByUserIdRegionId/${userId}/${regionId}`)
}
export function getUserAbilitiesWithLevel(userId, regionId) {
    return proxyAPIv2.get(`/getUserAbilityByUserIdRegionId/${userId}/${regionId}`)
}

export function getAppConfig() {
    return rootAPI.get(`/getAppConfig`)
}

export function redeemAcessCode(data,accessCodeValue,accessCodeOrigin){
    return accessCodeApi.post(`/accesscode/byvalue/${accessCodeValue}/${accessCodeOrigin}/redeem`,data)
}

export function getLiveChatConfig() {
    return rootAPI.get(`/getLiveChatConfig`)
}

export function getAppcuesConfig() {
    return rootAPI.get(`/getAppcuesConfig`)
}

export function getUserStatus() {
    return proxyAPI.get('/getUserStatus')
}

export function getOrganisationByUserId(userId) {
    return organizationAPI.get(`/organizations/inProductModelRegion/byUser/${userId}`)
}

export function fetchRoleInOrg(orgId, pmrId, userId) {
    return organizationAPI.get(`/organization/${orgId}/productModelRegions/${pmrId}/user/${userId}/permissionGroups`)
  }

export function fetchRoleInOrgv2(userId, orgId = null, orgGroupId = null, orgPmrs = [], orgGroupPmr = null) {
    return proxyAPI.post(`/userability/${userId}`, {
        organizationGroupId: orgGroupId,
        orgGroupPmrId: orgGroupPmr,
        organizationId: orgId,
        productModelRegions: orgPmrs
    })
  }  

export default {
    getFooterLinks,
    getHeaderInfo,
    getSpas,
    setAuthToken,
    getUserProfile,
    setUserIdHeader,
    getListofTranslation,
    redeemAcessCode,
    getOrganisationByUserId
};
