import {useEffect, useCallback, useRef} from 'react';

export const useOutsideClick = (elementRef, callback = () => { }) => {

    const handleClick = useCallback((e) => {
        const currentElement = elementRef.current;
        if (currentElement && !currentElement.contains(e.target)) {
            callback();
        }
    }, [elementRef, callback]);

    useEffect(() => {
        const currentElement = elementRef.current;
        if (currentElement) {
            document.addEventListener("click", handleClick);
        }

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [handleClick, elementRef]);
};


// Hack as ESLint rule "react-hooks/exhaustive-deps" will always fail on empty dependency lists
export const useMountEffect = (fun) => useEffect(fun, []);


const useIsMounted =  () => {
    const isMounted = useRef(false);

    useMountEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    });

    return isMounted;
};


/**
 * Identical to React.useEffect, except that it never runs on mount. This is the equivalent of
 * the componentDidUpdate lifecycle function.
 *
 * This is to be used where dependency is set using a setState hook and we don't want the effect to run for
 * the initial state.
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} dependencies - useEffect dependency list.
 */
export const useUpdateEffect = (effect, dependencies) => {
    const isMounted = useIsMounted();
    const isInitialMount = useRef(true);

    useEffect(() => {
        let effectCleanupFunc = () => {}; // noop function

        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            effectCleanupFunc = effect() || effectCleanupFunc;
        }
        return () => {
            effectCleanupFunc();
            if (!isMounted.current) {
                isInitialMount.current = true;
            }
        };
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};
