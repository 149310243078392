import React from 'react';
import routes from 'src/routes/config';
import { Routes } from 'src/components';

export function App() {
  //TODO: need routing here
  return (
    <Routes routes={routes} defaultRoute={routes[0].path} />
  );
}
