import React, { useState, useContext } from "react";
import { AppContext } from "src/App";
import { Button, PasswordInput } from "common-components-spa";
import "./ChangePassword.scss";
import { updateUserProfile } from "../../network/ApiService";
import { useHistory } from "react-router-dom";

// initial user object
const initUser = {
  confirmNewPassword: "",
  oldPassword: "",
  newPassword: "",
  validation: {
    confirmNewPassword: "",
    oldPassword: "",
    newPassword: "",
  },
};

export default function ChangePassword({
  handleAPIError,
  popupConfig: { toastMessage, setLoaderState, setConfirmedVisible },
}) {
  const { translate: t, defaultSpaId,setSelectedSpaId } = useContext(AppContext);
  const [inputsObject, setInputsObject] = useState(initUser);
  const history = useHistory();

  /**
   *  for handling form input fields
   */
  function handleInput(e) {
    const { name, value } = e.target;
    setInputsObject({
      ...inputsObject,
      [name]: value,
      validation: { ...inputsObject.validation, [name]: "" },
    });
  }

  /**
   *  form submit click handler
   */
  function updateClickHandler() {
    const { confirmNewPassword, oldPassword, newPassword } = inputsObject;
    if (confirmNewPassword && oldPassword && newPassword) {
      if (confirmNewPassword !== newPassword) {
        setInputsObject({
          ...inputsObject,
          validation: {
            ...inputsObject.validation,
            confirmNewPassword:
              "New Password and Confirm New Password don't match.",
          },
        });
        return false;
      }
      handleUpdatePassword();
      //handleUpdateDetailsSuccess();
    }
    let validationObj = {
      confirmNewPassword: "",
      oldPassword: "",
      newPassword: "",
    };
    if (!confirmNewPassword)
      validationObj.confirmNewPassword = "This is required field.";
    if (!oldPassword) validationObj.oldPassword = "This is required field.";
    if (!newPassword) validationObj.newPassword = "This is required field.";

    setInputsObject({
      ...inputsObject,
      validation: { ...inputsObject.validation, ...validationObj },
    });
  }

  const handleUpdatePassword = () => {
    const payLoad = {
      oldPassword: inputsObject.oldPassword,
      newPassword: inputsObject.newPassword,
    };
    setLoaderState(true);

    const userId = window.piSession.userId();
    updateUserProfile(userId, payLoad).subscribe(
      (response) => {
        toastMessage({
          msg: "<strong>Password</strong> updated Successfully !!!",
          success: true,
        });
        setInputsObject(initUser);
      },
      (error) => {
        setLoaderState(false);
        handleAPIError({
          error,
          callback: handleUpdatePassword,
        });
      },
      () => {
        setLoaderState(false);
      }
    );
  };

  const handleCancelClick = () => {
    const { confirmNewPassword, oldPassword, newPassword } = inputsObject;
    if (confirmNewPassword || oldPassword || newPassword) {
      setConfirmedVisible(true);
    } else {
      setSelectedSpaId(defaultSpaId)
    }
  };

  return (
    <>
      <div className="instructor__head">
        <div className="d-flex align-items-center">
          <div className="col-6 p-0">
            <h3 className="instructor__head__txt">{t("Change Password")}</h3>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-end">
              <Button
                value="Cancel"
                useButtonClass={false}
                className="button__v2 ripple--v2 button--v2__bordered minWidth-100 "
                onClick={handleCancelClick}
              />
              <Button
                value="Update"
                isDisabled={
                  inputsObject.confirmNewPassword &&
                    inputsObject.oldPassword &&
                    inputsObject.newPassword
                    ? false
                    : true
                }
                className="button__v2 ripple--v2 button__v2__primary__bordered minWidth-100"
                onClick={updateClickHandler}
                useButtonClass={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="instructor__body">
        <div className="d-flex align-item-center">
          <div className="col-6 pl-0">
            <div className="passwordchange__wrapper">
              <h4 className="passwordchange__heading">
                {t("Password Guidelines")}
              </h4>
              <ul className="passwordchange__list">
                <li className="passwordchange__list--item">
                  {t("Must be at least 8 characters")}
                </li>
                <li className="passwordchange__list--item">
                  {t("Must not be one of your previous 24 passwords")}
                </li>
                <li className="passwordchange__list--item">
                  {t(
                    "Must contain characters from at least 3 of the following 4 character groups:"
                  )}
                  <ul className="passwordchange__sublist">
                    <li className="passwordchange__sublist--item">
                      {t("English uppercase characters (A through Z)")}
                    </li>
                    <li className="passwordchange__sublist--item">
                      {t("English lowercase characters (a through z)")}
                    </li>
                    <li className="passwordchange__sublist--item">
                      {t("Numerals (0 through 9)")}
                    </li>
                    <li className="passwordchange__sublist--item">
                      {t("Symbols on your keyboard except £, €, ¤, ¥")}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="vl"></div>
          <div className="col-6">
            <PasswordInput
              frmgrpclsname="position-relative"
              inputtype={"text"}
              title={t("Old Password")}
              name={"oldPassword"}
              value={inputsObject.oldPassword}
              onChange={handleInput}
              placeholder={t("Type old password")}
              defaultValue={""}
              errorMessage={inputsObject.validation.oldPassword}
            />
            <PasswordInput
              frmgrpclsname="position-relative"
              inputtype={"text"}
              title={t("New Password")}
              name={"newPassword"}
              defaultValue={""}
              placeholder={t("Type new password")}
              onChange={handleInput}
              value={inputsObject.newPassword}
              errorMessage={inputsObject.validation.newPassword}
            />
            <PasswordInput
              frmgrpclsname="position-relative"
              inputtype={"text"}
              title={t("Confirm New Password")}
              name={"confirmNewPassword"}
              defaultValue={""}
              placeholder={t("Confirm new password")}
              onChange={handleInput}
              value={inputsObject.confirmNewPassword}
              errorMessage={inputsObject.validation.confirmNewPassword}
            />
          </div>
        </div>
      </div>
    </>
  );
}
