import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "common-components-spa/dist/index.min.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import { routerBasePath } from "src/constants/config";
import { ErrorBoundary, I18nContextProvider } from "common-components-spa";
import { AccessAndAdminApp } from "./BaseApp";

// const GatewayApp = () => {
//   return (
//     <I18nContextProvider>
//       <App />
//     </I18nContextProvider>
//   );
// };

ReactDOM.render(
  <ErrorBoundary errorPage="PageLoadFails">
    <HashRouter basename={routerBasePath}>
      <I18nContextProvider>
        <AccessAndAdminApp />
      </I18nContextProvider>
    </HashRouter>
  </ErrorBoundary>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
