import React, {useContext} from 'react';
import LoadingPageBody from './LoadingPageBody';
import {AutofillProgressBar} from 'common-components-spa';
import { AppContext } from 'src/App'

export const LoadingPageView = (props) => {
  const {translate} = useContext(AppContext);
  return (
    <div>
      <AutofillProgressBar {...props} />
      <LoadingPageBody t={translate} />
    </div>
  );
};
