import React, {useState, useContext, useEffect} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import './userLanguage.scss'
import {I18nContext} from 'common-components-spa'
import { AppContext } from 'src/App';
import { getListofTranslation } from 'src/network/ApiService';
import 'src/utils/eventEmitter';

const SelectDropdown = () => {
    const defaultlang = 'English';
    const [langCode, setLangCode] = useState(defaultlang);
    const {translate} = useContext(I18nContext);
    const { setTranslate, userlang } = useContext(AppContext);
    const [availableLang, setAvailableLang] = useState([{code:'en', name:defaultlang}])
    const GGWEvents = window.GGWLANGUAGE || {};
    
    const langUpdated = lang => {
        const selectedLang = availableLang.find(item => item.code === lang);
        setLangCode((selectedLang && selectedLang.name) || defaultlang)
        localStorage.setItem('p-user-lang', lang)
    }
    useEffect(()=>{        
        langUpdated(userlang);
    }, [availableLang, userlang])
    useEffect(()=>{
        getListofTranslation().subscribe((observer)=>{
            setAvailableLang(observer.GLOSSARY_LIST);
        })
    }, []) 
    useEffect(()=>{
        setTranslate(()=>translate)
    }, [translate])

    GGWEvents.hasOwnProperty('subscribe') && GGWEvents.subscribe('WRAPPER', 'SET_LANGCODE', langUpdated);  
    const handleChange = (lang) => { 
        GGWEvents.hasOwnProperty('trigger') && GGWEvents.trigger('LANGCODE_CHANGED', lang);
    }
    return(
    <Dropdown className="selectDropdown">
        <Dropdown.Toggle id="dropdown-basic" className="selectDropdown__btn">
            {langCode}
        </Dropdown.Toggle>
        <Dropdown.Menu className="selectDropdown__drop" >
            <div className="selectDropdown__drop__wrp">
                {
                    availableLang.map((language) =>
                    <Dropdown.Item  eventKey={language.code} className="selectDropdown__drop__item" onSelect={ handleChange }>{language.name}</Dropdown.Item>)
                }
            </div>
        </Dropdown.Menu>
    </Dropdown>)
}

export default SelectDropdown