export const fontList = [
    {
        'title': 'Default'
    },
    {
        'title': 'Sans'
    },
    {
        'title': 'High-Readability'
    }
];
