import { SpaWrapper } from "src/page/spaWrapper/SpaWrapper";
import { ServiceError } from "src/page/serviceError/ServiceError";
import PreLogin from 'src/page/preLogin/PreLogin';

const Routes = [
  {
    component: SpaWrapper,
    path: "/application/:applicationId?",
    exact: false,
    title: "Global Gateway Wrapper",
  },
  {
    component: ServiceError,
    path: "/service-error",
    exact: false,
    title: "Service Error 503",
  },
  {
    component: PreLogin,
    path: "/login",
    exact: false,
    title: "Pre Login",
  }
];

export default Routes;
