import React from "react";
import { PreferenceOptionsv2 } from "common-components-spa";

import "./UserPreference.scss";

const UserPreference = ({
  popupConfig: { toastMessage },
}) => {
  const piSessionObj = window.piSession;
  const host = window.location.host;

  const handleSuccess = () => {
    toastMessage({
      msg: "Preferences updated Successfully !!!",
      success: true,
    });
  }

  const handleFailure = () => {
    toastMessage({
      msg: "Preferences not updated !!!",
      success: false,
    });
  }

  return <PreferenceOptionsv2 onSuccess={handleSuccess} onFailure={handleFailure} allowChanges={true} piSessionObj={piSessionObj} host={host} />
};

UserPreference.defaultProps = {
  popupConfig: {
    toastMessage: () => { },
  },
};

export default UserPreference;