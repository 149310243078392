import React, { useContext, useState, useEffect } from "react";
import { Input, Button, AbilityContext } from 'common-components-spa';
import { AppContext } from "src/App";
import { redeemAcessCode } from "src/network/ApiService";

const RedeemAccessCode = (props) => {

  const {
    translate: t,
    setProgressPopupV2State,
    closeProgressPopupV2,
    defaultSpaId,
    setSelectedSpaId,
    userId,
    errorPopup,
    unAuthorizedErrorMessage,
    fetchHeaderInfo
  } = useContext(AppContext);
  const [disableButton, setButtonDisable] = useState(true)
  const [accessCodeValue, setAccessCodeValue] = useState("")
  const ability = useContext(AbilityContext);

  const onPopupClose = () => {
    setAccessCodeValue("")
    closeProgressPopupV2();
    setButtonDisable(true);
  }
  const goToHome = () => {
    closeProgressPopupV2();
    fetchHeaderInfo();
    setSelectedSpaId(defaultSpaId);
  }
  const initialProgressConfig = {
    isOpen: true,
    info: t("Please wait, while we validate your access code"),
    animationDuration: 4,
    progressDone: false,
    onProgressBarFilled: () => { },
    progressErrorInfo: "",
    showProgressBar: true,
    propgresstypeIcon: ``,
    onCompleteInfotext: t("Successfully created"),
  }
  const handlePopButton = () => {
    errorPopup.setVisibility(false);
  }
  const handleInput = (e) => {
    const { target: { value } } = e;
    if (!value) { setButtonDisable(true); setAccessCodeValue(""); }
    else {
      disableButton && setButtonDisable(false)
      setAccessCodeValue(value);
    }

  };

  const errorConfig = (err) => {
    setProgressPopupV2State({
      progressDone: true,
      propgresstypeIcon: "",
      progressInfo: "",
      info: "",
      showProgressBar: false,
      showSuccessImage: false,
      footerClass: "share-border mt-4",
    });

    const errorState = {
      unAuthorized: {
        ...unAuthorizedErrorMessage,
        onClosePopup: handlePopButton,
        buttonAction: handlePopButton,
        cancelButtonAction: handlePopButton
      },
      fetchError: {
        heading: err.data ? t(err.data.detail) : t("Oops!! Something went wrong"),
        image: `${process.env.PUBLIC_URL}/images/generic-error-icon.svg`,
        btnName: t("Okay, Got it"),
        btnStyle: true,
        onClosePopup: () => errorPopup.setVisibility(false),
        buttonAction: () => errorPopup.setVisibility(false)
      }
    }
    return errorState;
  }

  const accessCodeRedeem = () => {

    const requestBody = {
      redemptionUserId: userId
    }
    const getQueryString = window.location.hash.split('accessCodeOrigin');
    const accessCodeOrigin = getQueryString.length > 1 ? window.location.hash.split('accessCodeOrigin')[1].replace('=', '') : 'gps'

    setProgressPopupV2State({
      ...initialProgressConfig,
    });


    redeemAcessCode(requestBody, accessCodeValue, accessCodeOrigin).subscribe((res) => {
      setProgressPopupV2State({
        isOpen: true,
        info: t(`Access code validated successfully`),
        animationDuration: 0,
        loadingBg: `${process.env.PUBLIC_URL}/images/success-access.svg`,
        progressDone: "",
        onProgressBarFilled: () => { },
        progressErrorInfo: "",
        showProgressBar: false,
        propgresstypeIcon: ``,
        onCompleteInfotext: t(`Successfully created`),
        subInfo: t(`Product Model is enabled under platforms and services`),
        footerClass: 'justify-content-center',
        showSuccessImage: false,
        customFooter: () => (
          <>
            <hr />
            <div class="pt-3 links link-container">
              <div class="pr-3 show-cursor link-text-first" onClick={goToHome}>{t(`Go to home`)}</div>
              <div class="border-left pl-3 show-cursor link-text-second" onClick={onPopupClose} >{t(`I have more access code`)}</div>
            </div>
          </>
        )
      })
    }, (err) => {
      if (err && err.status === 403) {
        const errorMsg = errorConfig(err).unAuthorized
        errorPopup.setState(errorMsg);
        errorPopup.setVisibility(true);
      }
      else {
        const errorMsg = errorConfig(err).fetchError
        errorPopup.setState(errorMsg);
        errorPopup.setVisibility(true);

      }
    })
  };

  useEffect(() => {
    setSelectedSpaId("redeemcode")
  }, [])

  return (
    <>
      <div className="redeem">
        <div className="d-flex align-items-center">
          <div className="w-100">
            <Input title={t("Enter Access Code")}
              placeholder={t("XXXX-XXXX-XXXX-XXXX")}
              onChange={handleInput}
              name={"accessCode"}
              value={accessCodeValue}
            />
            <span className="redeem__samplecode">{t(`E.g. B72C-RE34-12QW-1QZ4`)}</span>
          </div>
          <div class="ml-3 mt-1">

            <Button
              value={t("Redeem")}
              useButtonClass={false}
              className="button--v2 ripple--v2 button--v2__secondary  rosterbtn"
              onClick={accessCodeRedeem}
              isDisabled={disableButton}

            />
          </div>
        </div>
        <span className="pt-4 popupacces" onClick={() => props.showErrorPopup()}>{t(`How do I find the access code?`)}</span>
      </div>
    </>
  );
};

export default RedeemAccessCode;
