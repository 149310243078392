import App from "./App";
import PreLogin from "./page/preLogin/PreLogin";
import {Routes} from "./components/routes/Routes";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { setAuthToken } from "./network/ApiService";
import { IESAuthUtilV2 } from "common-components-spa";
import { IESConfig, IESOptions } from "./constants/IESConfig";


// export function BaseApp(props) {
//     return (
//         <>
//             <Switch>
//                 <Route path="/application/:applicationId?">
//                     <Switch>
//                         <Route exact={false} path={"/application/:applicationId?"} render={() => <div>Component 1</div>} />
//                         <Route exact={false} path={"/service-error"} render={() => <div>Component 2</div>} />
//                     </Switch>
//                 </Route>
//                 <Route path="/prelogin"><div>Goodbye</div></Route>
//                 <Redirect to="/application/:applicationId?" />
//             </Switch>
//         </>
//     )
// }

export const AuthenticationContext = createContext();

export function AccessAndAdminApp() {

  const [isTokenExist, setIsTokenExist] = useState(false);
  const [iesSession, setIesSession] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hasFatalError, setHasFatalError] = useState(false);

  const setToken = useCallback((tokenValue) => {
    setAuthToken(tokenValue);
    setIsTokenExist(true);
  }, []);

  useEffect(() => {
    const loginTypeVal = sessionStorage.getItem("LOGINTYPE");
    const loginParams = (loginTypeVal) ? { queryParams: { loginType: loginTypeVal } } : {};
    const iesSubscription = IESAuthUtilV2.load({
      ...IESConfig,
      callbackURL: `${window.location.href}`,
      domain: window.location.hostname,
      ...loginParams
    }, IESOptions).subscribe((session) => {
      if (!window.location.hash.includes('login')) {
        session.login();
      }
      setIesSession(session);
      if (session.hasValidSession()) {
        setUserId(session.getUserId());
      }
      session.onRefresh((refreshEvent) => {
        refreshEvent.data && setUserId(session.getUserId());
        refreshEvent.data && setToken(refreshEvent.data);
      });
      session.onLogin((loginData) => {
        console.log('loginData = ', loginData);
      });
    }, 
    (error) => {
      setHasFatalError(true)
    });

    return () => iesSubscription.unsubscribe();
    // no need to call ies again if setters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const baseRoutes = useMemo(() => {
        return [
            {
                component: App,
                path: "/application/:applicationId?",
                exact: false,
                title: "Global Gateway Wrapper",
            },
            {
                component: PreLogin,
                path: "/login",
                exact: false,
                title: "Pre Login Page"
            }
        ]
    }, [])

    const authenticationContextValues = useMemo(() => {
        return {
            iesSession,
            userId,
            isTokenExist,
            hasFatalError,
            setIsTokenExist
        }
    }, [iesSession, userId, isTokenExist, hasFatalError])

    

    return (
        <AuthenticationContext.Provider value={authenticationContextValues}>
            <Routes routes={baseRoutes} defaultRoute={baseRoutes[0].path} />
        </AuthenticationContext.Provider>
    );
}
