import { lazy } from 'react';


const AccessCodeWrapper = lazy(() => import('../page/acessCodeWrapper/accessCodeWrapper'));
export const itemsList = [{
    id: '1',
    icon: `${process.env.PUBLIC_URL}/images/course-instance.svg`,
    label: 'AC Redemption',
    activeIcon: `${process.env.PUBLIC_URL}/images/course-instance.svg`,
    path: '/redeemcode',
    exact: false,
    component: AccessCodeWrapper,
    action: "READ",
    subject: "PRODUCT_MODEL"
}
];