export const IESConfig = {
  // clientId: "4rdaaSKTN6xgVqeRAXJLWQc9DePEBbMA",
  // IES_baseURL: "https://login-qa.pearson.com/v1/piapi-test/",
  callbackURL: `${window.location.origin}/ies-callback`
};

export const IESOptions = {
  'checkSessionMilliseconds': 3000,   // How often session state is updated. (default 3000)
  'sessionIdleTimeoutSeconds': 5400,   // How much idle time should pass before session is killed. (default 1800)
  'defaultTimeoutSeconds': 200,         // How long to wait for calls to fetch access tokens
  'loginSuccessUrl': window.location.href, // Redirect here after sucessful login
  'idpId': '',                         // Internal identity provider ID for federated login
  'requireLogin': false,               // If true, this page will redirect to a login screen any time there is no current login session
  'autoRefresh': true,                 // If true, tokens will automatically refresh as needed
  'monitorUserActivity': true,         // If true, event handlers will be hooked up to record user activity upon standard UI events
  'sessionIdleTimeoutWarningSeconds': 100,  // How long before session idle timeout the user should be warned (3.13)
  'useDefaultIdleTimoutWarningPopup': true, // If true, a standard modal popup mechanism will be added to this page to show idle timeout warnings (3.13)
  'lang': 'en',                         // Locale language to propagate to login pages (default "en")
};