import React, { useContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import "./Header.scss";
import { AppContext } from 'src/App';
import { serviceError } from '../../constants/config';
import GoodMorningImage from '../../styles/images/Good-morning.svg';
import GoodAfternoonImage from '../../styles/images/good-afternoon.svg';
import GoodEveningImage from '../../styles/images/good-evening.svg';
import MenuImage from '../../styles/images/menu.svg';
import PearsonImage from '../../styles/images/Pearson-mobilelogo.svg';
import SelectDropdown from '../userLanguage/userLanguage'
import { Button } from 'common-components-spa'
import { supportUrls } from "src/constants/supportUrlAH";

export function Header(props) {
  const appContext = useContext(AppContext);
  const { selectedSpaId, activeHubInfo, setToast, defaultSpaId, entities } = appContext;
  const [messageCount, setMessageCount] = useState(0)
  const [expand, setExpand] = useState(true)
  const { isActiveHub } = activeHubInfo;
  const [headerImageLoaded, setHeaderImageLoaded] = useState(false);

  function handleHeaderImageLoad() {
    if (!headerImageLoaded) {
      setHeaderImageLoaded(true);
    }
  }

  function handleBtnKeyDown(event) {
    event.preventDefault();
    if (event.key === " " || event.key === "Enter" || event.key === "Spacebar") {
      toggleSideNav()
    }
  }
  function toggleSideNav() {
    if (expand) {
      document.getElementById("sidebar-container").classList.add("side-nav__exp");
    } else {
      document.getElementById("sidebar-container").classList.remove("side-nav__exp");
    }
    setExpand(!expand)
  }

  function keyPressed(event) {
    event.preventDefault();
    if (event.key === "Enter") {
      props.showDropDown();
    }
  }

  const handleSupportClick = (evt) => {
    evt.preventDefault();
    // const supportSection = sessionStorage.getItem("help-section");
    // if (!supportSection) {
    //   window.open("https://support.pearson.com/uk/s/", "_blank")
    //   return;
    // }
    // window.open(supportUrls[supportSection], "_blank");

    window.open("https://www.pearsonschoolsandfecolleges.co.uk/help/helphub?categoryBlog=05664d86-e5d6-4250-b970-69137b09f1d5", "_blank");
  }
  
  useEffect(() => {
    if (!(isActiveHub && window.USERPROFILE && window.USERPROFILE.firstName)) return;
    if (defaultSpaId === selectedSpaId && messageCount === 0) {
      setMessageCount(1)
      let today = new Date();
      let curHr = today.getHours();
      let greeting = '', toastImg = '';

      if (curHr < 12) {
        greeting = 'Good morning';
        toastImg = GoodMorningImage;
      } else if (curHr < 18) {
        greeting = 'Good afternoon';
        toastImg = GoodAfternoonImage;
      } else {
        greeting = 'Good evening';
        toastImg = GoodEveningImage;
      }
      setToast({
        title: '',
        msg: `${greeting} ${entities.decode(entities.decode(window.USERPROFILE.firstName))}`,
        styleClass: "greetings",
        status: 'inf',
        delay: 2000,
        autoHide: true,
        toastImg: `${toastImg}`,
        show: true
      });
    }

  }, [window.USERPROFILE, isActiveHub, selectedSpaId])
  return (
    <div className={`gwHeader ${isActiveHub && 'gwHeader__ahub'}`}>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="gwHeaderLogo">
              <a href="#" onClick={handleBtnKeyDown} onKeyDown={handleBtnKeyDown}>
                <img
                  src={props.headerImage}
                  // title={props.headerDetails.description}
                  alt="Pearson Global Gateway"
                  onClick={(selectedSpaId !== serviceError) && props.handleHeaderClick}
                  className={`gwHeaderLogo__img gwHeaderLogo__${(headerImageLoaded) ? 'hide' : 'show mbnone'}`}
                />
                <img
                  src={props.headerDetails.headerImageUrl}
                  // title={props.headerDetails.description}
                  alt="Pearson Global Gateway"
                  onClick={(selectedSpaId !== serviceError) && props.handleHeaderClick}
                  onLoad={handleHeaderImageLoad}
                  className={`gwHeaderLogo__img gwHeaderLogo__${(headerImageLoaded) ? 'show' : 'hide'}`}
                />
                <img src={MenuImage} className="pr-3 d-xl-none" title="menu" alt="menu" onClick={toggleSideNav} />
                <img src={PearsonImage} className="d-xl-none" title="Pearson global gateway" alt="Pearson Global Gateway" />
              </a>
            </div>

            {(props.showWelcomeMsg && !isActiveHub) &&
              <div className="gwHeaderLogo__note">
                <h1 className="gwHeaderLogo__notetxt">{props.headerDetails.welcome}</h1>
              </div>}
          </div>
          {/* <p style={{ userSelect: "none" }}>{props.headerDetails.welcome}</p> */}
          <div className="d-flex align-items-center">
            {!isActiveHub ?
              <div className="gwLanguage mr-lg-4 mr-md-4 mr-sm-2 mr-2">
                <SelectDropdown />
              </div> : <div className="gwhelp">
                <a href="https://www.pearsonschoolsandfecolleges.co.uk/help/helphub?categoryBlog=05664d86-e5d6-4250-b970-69137b09f1d5" className="material-icons gwhelp-icon"
                  rel="noopener noreferrer" target="_blank" onClick={handleSupportClick}>help_outline
                  <span class="screen-reader-only">Opens in a new window</span></a> {/*old help: https://support.pearson.com/uk/s/ */}
              </div>}

            <div className="gwProfile">
              <ul>
                <li className="gwAvatar gwProfile__dropdownbtn">
                  <Button className={`gwAvatar__btn ${isActiveHub && 'active-hub'} m-0 p-0`}
                    aria-controls="user_menu"
                    value={props.userInitials}
                    onClick={props.showDropDown}
                    onKeyPress={keyPressed}
                    haspopup={true}
                  />
                  {/* {props.userDetails.avatar ? <img class="gwAvatar__image" src="images/icon-school-user.svg" alt="school user image" /> : */}

                  {/* <label title={props.userInitials} className="gwAvatar__text">{props.userInitials}</label> */}
                  {props.children}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Header.defaultProps = {
  headerDetails: {
    name: "Pearson",
    description: "pearson global gateway",
    welcome: "Welcome!"
  },
  headerImage: `${process.env.PUBLIC_URL}/images/Pearson-logo.png`
}

Header.propTypes = {
  showDropDown: PropTypes.func.isRequired,
  userInitials: PropTypes.string,
  headerImage: PropTypes.string,
  headerDetails: PropTypes.object,
  handleHeaderClick: PropTypes.func,
  showWelcomeMsg: PropTypes.bool
}
