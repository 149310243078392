import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useOutsideClick } from 'src/utils/CustomHooks';
import { AppContext } from 'src/App';

export function AccesibilityPopup(props) {
    const {translate:t} = useContext(AppContext)
    const [fontdecrease, setfontdecrease] = useState('')
    const [fontincrease, setfontincrease] = useState('')
    const [latestsize, setLatestsize] = useState(0);

    const accesibilityRef = useRef();
    const minFontSize = 12;
    const maxFontSize = 16;

    useEffect(() => {
        const bodyElement = document.querySelector("html");
        const computedFontSize = window.getComputedStyle(bodyElement, null).getPropertyValue('font-size');
        const default_size = parseInt(computedFontSize.substr(0, computedFontSize.length - 2));
        const fromlocalstore = parseInt(localStorage.getItem("user-font-size"));
        setLatestsize( fromlocalstore? fromlocalstore: default_size)
    },[])
    
    useEffect(()=>{
        (latestsize === minFontSize)? setfontdecrease('disable__fontoption') : setfontdecrease('');
        (latestsize === maxFontSize)? setfontincrease('disable__fontoption') : setfontincrease('');
    }, [latestsize])

    function handleFontClick(evt) {
        evt.preventDefault();
        props.handleFontStyle(evt.currentTarget.dataset.value);
    }
    
    function handleDecreaseFontClick(evt) {        
        evt.preventDefault();  
        if(evt.target.parentElement.classList.contains("disable__fontoption")){
            return false;
        }
        setLatestsize(latestsize => latestsize - 1);
    }

    function handleIncreaseFontClick(evt) {        
        evt.preventDefault();
        if(evt.target.parentElement.classList.contains("disable__fontoption")){
            return false;
        }
        setLatestsize(latestsize => latestsize + 1);         
    }

    useEffect(()=> {
        document.querySelector('html').style.fontSize = `${latestsize}px`;
        localStorage.setItem("user-font-size", latestsize)        
        window.scrollTo({
            left: 0, 
            top: document.querySelector('body').scrollHeight,          
            behavior: 'auto'
        })
    }, [latestsize])

    useOutsideClick(accesibilityRef, () => {
        props.closePopup(false);
    });

    if (!props.isOpen) return null;

    return (
        <div className="gwaccess__dropdown" ref={accesibilityRef}>
            {/* <ul className="gwaccess__dropdown--style">
                {
                    props.fontList.map((font, index) => {
                        return (
                            <li
                                key={index}
                                tabIndex=""
                                title={t(font.title)}
                                data-value={font.title}
                                onClick={handleFontClick}
                                style={{ userSelect: "none" }}>
                                {t(font.title)}
                            </li>
                        )
                    })
                }
            </ul> */}
            <div className="gwFooter_row gwFooter_align_items_center gwFooter_ml_0 gwaccess__dropdown--size">
                <div className="gwFooter_col_6 gwFooter_border_right">
                    <div className={`gwFooter_py_1 gwFooter_d_flex gwFooter_align_items_center gwFooter_justify_content_center gwfontsize__decrease ${ fontdecrease }`}>
                        <button
                            className="gwbutton"
                            placeholder=""
                            aria-label={t("Accessibility")}
                            tabIndex=""
                            title={t("Decrease font size")}
                            onClick={handleDecreaseFontClick}
                        >
                            -
                        </button>
                        <label className="gwlabel">Aa</label>
                    </div>
                </div>
                <div className="gwFooter_col_6">
                    <div className={`gwFooter_py_1 gwFooter_d_flex gwFooter_align_items_center gwFooter_justify_content_center gwfontsize__increase ${ fontincrease }`}>
                        <label className="gwlabel">Aa</label>
                        <button
                            className="gwbutton"
                            placeholder=""
                            aria-label={t("Accessibility")}
                            tabIndex=""
                            title={t("Increase font size")}
                            onClick={handleIncreaseFontClick}
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

AccesibilityPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    fontList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string
        })
    ).isRequired,
    handleFontStyle: PropTypes.func.isRequired,
    handleDecreseFont: PropTypes.func.isRequired,
    handleIncreaseFont: PropTypes.func.isRequired
}

