import React from 'react'
import './LoadingPage.scss'

const LoadingPageBody = ({t}) =>
  <div className="position-relative gwUxContainer pt-4">
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-9 text-center">
          <div className="offset-md-1 gwUx__center-blk">
            <p className="gwUx__center-blk--text">{t('Please wait while we are loading your experience')}</p>
            <img className="gwUx__center-blk--img" src={`${process.env.PUBLIC_URL}/images/image-01.svg`} alt={t("loading")} />
          </div>
        </div>
        <div className="col-md-3">
          <div className="gwUx__left-blk">
            <img src={`${process.env.PUBLIC_URL}/images/wall-gate.svg`} alt="gateway" />
          </div>
        </div>
      </div>
    </div>
  </div>


export default LoadingPageBody
