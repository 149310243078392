
import React, { useEffect } from "react";
import "./LiveChatButton.scss";
import ScriptCache from "../micro-frontend/script-cache";
import { getLiveChatConfig } from "src/network/ApiService";
import { useNetworkResponse } from "common-components-spa";
const offlineButtonId = 'liveagent_button_offline_';
const onlineButtonId = 'liveagent_button_online_';

const LiveChatButton = () => {
    const liveChatConfigResponse = useNetworkResponse();

    useEffect(() => {
        liveChatConfigResponse.dispatch({ type: "STARTED" });
        const subscription = getLiveChatConfig().subscribe(
            (response) => {
                liveChatConfigResponse.dispatch({ type: "SUCCESS", payload: response });
                const scriptCache = new ScriptCache();
                const chatURLS = [response.DEPLOYEMENT_URL]
                scriptCache.setScripts(chatURLS)
                    .setContainerId("live-chat-button")
                    .load(chatURLS).then(() => {
                        if (!window._laq) { window._laq = []; }
                        window._laq.push(() => {
                            window.liveagent.showWhenOnline(response.CHAT_ID, document.getElementById(onlineButtonId + response.CHAT_ID));
                            window.liveagent.showWhenOffline(response.CHAT_ID, document.getElementById(offlineButtonId + response.CHAT_ID));
                        })
                        window.liveagent.init(response.INIT_URL, response.INIT_ARG1, response.INIT_ARG2);
                    });
            },
            (error) => {
                console.log("live chat config fetch error")
            }
        );
        return () => {
            subscription.unsubscribe();
        };
    }, [])

    const handleChatButton = () => {
        window.liveagent.startChat(liveChatConfigResponse.data.CHAT_ID)
    }

    const liveChatConfig = liveChatConfigResponse.data;
    const enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) {
            // on press enter
            handleChatButton();
        } 
    }

    return (liveChatConfig && <div className="live-chat-button">
        <button id={onlineButtonId + liveChatConfig.CHAT_ID} style={{ display: 'none' }}
            onKeyPress={enterPressed} onClick={handleChatButton} tabIndex="1" aria-disabled={false}>Chat with an Expert
             <span className="sr-only">Opens in a new window</span>
            </button>
        <button id={offlineButtonId + liveChatConfig.CHAT_ID}
            tabIndex="1" aria-disabled={true}>Agent offline</button>
    </div>);
}

export default LiveChatButton;