import { Loader, UserNotEntitled } from 'common-components-spa';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/App';
import {
  getFooterLinks,
  getUserProfile
} from 'src/network/ApiService';
import { Home } from 'src/page';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { serviceError } from '../../constants/config';
import {isRedeemCodePage} from '../../utils/utils'

export function Callback() {
  const [loadingDone, setLoadingDone] = useState(false);

  const appContext = useContext(AppContext);

  const {
    headerDetails,
    footerDetails,
    iesSession,
    region,
    locale,
    setFooterDetails,
    setUserName,
    userName,
    setSelectedSpaId,
    wrapperLevelErrorPage,
    setWrapperLevelErrorPage
  } = appContext;

  useEffect(() => {
    if (!region) return;
    appContext.setFooterDetails(null);
    const subscription = getFooterLinks(region, locale)
      .pipe(
        catchError((err) => {
          if (err && err.data && err.data.status === 400) {
            return of([]);
          }
          throw err;
        })
      )
      .subscribe(
        response => setFooterDetails(response),
        error => {
          if (error && error.data && error.data.status >= 500) {
            setSelectedSpaId(serviceError)
            setFooterDetails([])
          } else if (error && error.data && error.data.status === 401) {
            iesSession.logout();
          } else if (error && error.status === 403) {
            setWrapperLevelErrorPage(<UserNotEntitled />)
          }
          console.log('Error in API', error)
        },
      );

    return () => subscription.unsubscribe();
    // dont need to run this effect when setter change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, region]);

  useEffect(() => {

    setUserName(null);

    const subscription = getUserProfile(iesSession.getUserId())
      .subscribe(
        response => {
          if (!response) {
            console.log('error in api', 'no data in user api');
            return;
          }

          const givenName = response.firstName;
          const familyName = response.lastName;
          let firstInitial = givenName && givenName.length > 0 &&
            givenName[0].toUpperCase();
          let familyInitial = familyName && familyName.length > 0 &&
            familyName[0].toUpperCase();
          setUserName(
            `${firstInitial || ''}${familyInitial || ''}`,
          );
          window.USERPROFILE = { ...response }
        },
        error => {
          if (error && error.data && error.data.status >= 500) {
            setSelectedSpaId(serviceError)
            setUserName('-')
          } else if (error && error.data && error.data.status === 401) {
            iesSession.logout();
          } else if (error && error.data && error.data.status === 404) {
            setUserName('-')
          }
          console.log('error in api', error)
        },
      );

    return () => subscription.unsubscribe();
    // dont need to run this effect when setters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Loading done', headerDetails, footerDetails, userName, wrapperLevelErrorPage);
    if ((headerDetails && footerDetails && userName) || wrapperLevelErrorPage || (!headerDetails && !footerDetails && !userName && isRedeemCodePage)) {
      setLoadingDone(true);
    } else {
      setLoadingDone(false);
    }
  }, [headerDetails, footerDetails, userName, wrapperLevelErrorPage, isRedeemCodePage]);

  if (!loadingDone) {
    return (
      <Loader />
    );
  }
  return <Home />;
}
