import React, { useMemo, useRef, useContext } from "react";
import PropTypes from 'prop-types';
import { AppContext } from "src/App";
import { useOutsideClick } from "common-components-spa";
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import './profileDropDown.scss'
export function ProfileDropDown(props) {
  const dropDownRef = useRef();

  useOutsideClick(dropDownRef, () => {
    if (props.isOpen) {
      props.showDropDown(false);
    }
  });
  const { 
    entities
  } = useContext(AppContext);
  const hrefs = useMemo(() => {
    let attr = {};
    if(props.isOpen) {
      attr['href'] = "#";
    }
    
    return attr;
  }, [props.isOpen]);

  const onClickOption=(callback)=>{
    return (event)=>{
      event.preventDefault();
      callback(event);
    }
  }

  //if (!props.isOpen) return null;
  return (
    <ul
      className={`gwProfile__dropcontent ${props.isOpen ? `gwProfiledrop__visible` : ` `}`} ref={dropDownRef} id="user_menu">
      <li>
        {
          (window.USERPROFILE && props.isActiveHub) &&
          <div className="border-bottom wpspc">
            <div className="username">{`${entities.decode(entities.decode(window.USERPROFILE.firstName))} ${entities.decode(entities.decode(window.USERPROFILE.lastName))}`}</div>
            <div href="" tabIndex="" className="userinfo">
              <InfoTooltip className="d-inline-flex align-middle" dataclass="info-tooltip__data--bottom -headerbottom"
                data={
                  <div className={`gwNotification `}>
                    <div className="gwNotification__icon">
                      <span>{window.USERPROFILE.email}</span>
                    </div>
                  </div>
                }
                tooltip={window.USERPROFILE.email}
              /></div>
          </div>
        }

      </li>
      {
        props.accountDropdownOptions.map(item => (
          <li>
            <a
              tabIndex={`${props.isOpen ? "0" : ""}`}
              key={item.label}
              {...hrefs}
              className={`gwProfile__dropcontent__list${props.isActiveHub && '__ahub'}`}
              data-target="#logout-popup"
              data-toggle="modal"
              style={{ userSelect: "none", textDecoration: "none" }}
              onClick={onClickOption(item.action)}>
              {item.label}
            </a>
          </li>
        ))}
    </ul >
  )
}

ProfileDropDown.propTypes = {
  accountDropdownOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  })).isRequired,
  isOpen: PropTypes.bool,
  showDropDown: PropTypes.func.isRequired
}
