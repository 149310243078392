import React, { useState, useContext } from "react";
import {
  Header,
  ProfileDropDown,
  Footer,
  AccesibilityPopup,
  LogOutPopUp,
} from "src/components";
import { fontList } from "src/constants/FooterInputs";
import { headerImage } from "src/constants/HeaderInputs";
import "./Home.scss";
import { Landing } from "../landing/Landing";
import { AppContext } from "src/App";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ErrorBoundary } from "common-components-spa";

export function Home() {
  // hook to maintain open and close state for popUp  and footer popUp
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [showAccesibilityPopup, setShowAccesibilityPopup] = useState(false);
  const [showlogOutPopUp, setShowlogOutPopUp] = useState(false);
  const appContext = useContext(AppContext);
  const {
    headerDetails,
    footerDetails,
    iesSession,
    userName,
    defaultSpaId,
    selectedSpaId,
    setSelectedSpaId,
    activeHubInfo,
    translate: t,
    wrapperLevelErrorPage,
    onRouteFromUnsavedFormHOF
  } = appContext;
  const { isActiveHub } = activeHubInfo
  const accountDropdownOptions = [
    {
      label: t("Account preferences"),
      action: () => redirectLink('preferences'),
    },
    {
      label: t("User Profile"),
      action: () => redirectLink('userProfile'),
    },
    {
      label: t("Change Password"),
      action: () => redirectLink('updatePassword'),
    },
    {
      label: t("Sign Out"),
      action: openLogOutPopUp,
    },
  ];
  const activeHubDropdownOptions = [
    {
      label: t("My account"),
      action: () => onRouteFromUnsavedFormHOF(()=>redirectLink('userProfile')) ,
    },
    {
      label: t("Sign out"),
      action: openLogOutPopUp,
    },
  ];
  const { path: matchPath } = useRouteMatch();
  let hashLocation = ((window.location.hash || '').split('/')).pop();

  // redirect to page
  const redirectLink = (url) => {
    setIsOpen(!isOpen);
    setSelectedSpaId(url)
  }

  //callback for header image click
  function openLogOutPopUp() {
    setShowlogOutPopUp(!showlogOutPopUp);
    setIsOpen(!isOpen);
  }

  function closeLogOutPopUp() {
    setShowlogOutPopUp(false);
  }
  function handleHeaderClick() {
    onRouteFromUnsavedFormHOF(()=>setSelectedSpaId(defaultSpaId))
  }

  // function to handle open and close state of popUp components
  function showDropDown(event) {
    setIsOpen(!isOpen);
  }

  function handleLogout(event) {
    event.stopPropagation();
    event.preventDefault();
    closeLogOutPopUp();
    iesSession.logout();
  }

  function handleFontStyle() { }

  function handleDecreseFont() { }

  function handleIncreaseFont() { }

  function closeAccesibilityPopup() {
    setShowAccesibilityPopup(false);
  }

  function onAccesibilityClick() {
    setShowAccesibilityPopup(true);
  }

  const headerDetailsProps = headerDetails
    ? { headerDetails: headerDetails }
    : {};

  return (
    <div className="gwWrapper gatewayLandingWrapper">
      <LogOutPopUp
        handleLogout={handleLogout}
        show={showlogOutPopUp}
        close={closeLogOutPopUp}
      />
      <header className="gwWraper_header">
        <Header
          userInitials={userName}
          headerImage={headerImage}
          showDropDown={showDropDown}
          handleHeaderClick={!wrapperLevelErrorPage && handleHeaderClick}
          showWelcomeMsg={
            selectedSpaId === defaultSpaId && !wrapperLevelErrorPage
          }
          {...headerDetailsProps}
        >
          <ProfileDropDown
            accountDropdownOptions={isActiveHub ? activeHubDropdownOptions : accountDropdownOptions}
            isOpen={isOpen}
            isActiveHub={isActiveHub}
            showDropDown={showDropDown}
          />
        </Header>
      </header>
      <div class="skipnav">
        <a href="#container-wrapper">Skip to main content</a>
      </div>
      {/* <NavigationHeader tabs={tabsList} activeTabId={activeTabId} onSelect={setActiveTabId}/> */}
      <main className="gwWrapper_content">
        {wrapperLevelErrorPage && hashLocation !== "preferences" ? (
          <div className="gwSpaWrapper__iframe"> {wrapperLevelErrorPage} </div>
        ) : (
            <ErrorBoundary errorPage="PageLoadFails">
              <Landing />
            </ErrorBoundary>
          )}
      </main>
      <footer  className="gwWraper_footer">
        <Footer
          footerLinks={footerDetails || []}
          copyRight={headerDetails && headerDetails.copyright}
          toggleAccessibilityDialog={onAccesibilityClick}
          isAccesibilityPopupOpen={showAccesibilityPopup}
        >
          <AccesibilityPopup
            isOpen={showAccesibilityPopup}
            closePopup={closeAccesibilityPopup}
            fontList={fontList}
            handleFontStyle={handleFontStyle}
            handleDecreseFont={handleDecreseFont}
            handleIncreaseFont={handleIncreaseFont}
          />
        </Footer>
      </footer>
    </div>
  );
}
