window.GGWLANGUAGE = (function(){
    var observerList={};  
    return {
      subscribe(spaName, evtType, listener) {
        this.unsubscribe(spaName, evtType)
        if(!observerList[spaName]){
            observerList[spaName] = {};
        }
        if(!observerList[spaName][evtType]) {          
            observerList[spaName][evtType] = [];
            observerList[spaName][evtType].push(listener)
        }
      },
      unsubscribe(spaName, eventType) {
        observerList[spaName] && observerList[spaName][eventType] && delete observerList[spaName][eventType];
      },
      trigger(evtType, data) {
         Object.keys(observerList).forEach(eachItem => {
          observerList[eachItem][evtType] && observerList[eachItem][evtType][0].call(null, data)
         })
      }
    };
  })();