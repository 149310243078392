import React, { useContext, useMemo } from 'react';
import './ServiceError.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AppContext } from 'src/App';
import MicroFrontend from '../../components/micro-frontend/microfrontend';

export function ServiceError() {
  const history = useHistory();
  const match = useRouteMatch();
  const applicationId = 'serviceError';
  const appContext = useContext(AppContext);

  const { spaDetailsList: spaConfig } = appContext;
  const { url, spaName } = useMemo(() => {
    return { url: '/spa/service-error', spaName:  null }
  }, [applicationId, spaConfig]);

  const renderLayout = () => {
    console.log('Callback service error ', url);
    return <MicroFrontend history={history} match={match} host={url} name={spaName} containerId={applicationId} />
  };

  return (
    <div className="gwSpaWrapper">
      <div className="gwSpaWrapper__error__iframe">
        {renderLayout()}
      </div>
    </div>
  );
}