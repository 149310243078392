import React from "react";
import { Button } from "common-components-spa";

import "./upload-pic.scss";

const UploadPic = ({ onClick }) => (
  <div className="upload-blk">
    <Button
      onClick={onClick}
      styleClass="button--v2 ripple--v2 upload-blk__btn p-0"
      value={<img alt="profileImage" src={process.env.PUBLIC_URL + '/images/profile.svg'} />}
    />
    <div className="upload-blk__txt">
      <h6 className="upload-title">Upload profile picture</h6>
      <label className="upload-desc">File type supported jpg, png</label>
      <label className="upload-desc">File size max 32kb</label>
    </div>
  </div>
);

export default UploadPic;
