import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './Footer.scss';
import { CommonUtils } from 'common-components-spa';
import {AppContext} from 'src/App'
export function Footer(props) {
    const appContext = useContext(AppContext);
    const { entities } = useContext(AppContext);
    const { activeHubInfo } = appContext;
    const { isActiveHub } = activeHubInfo;

    function handleAccessibilityClick() {
        if (!props.isAccesibilityPopupOpen) {
            props.toggleAccessibilityDialog(true);
        }

    }

    const footerLinksURL = (link, launchPath, launchType) => {
        let launchUrl = launchPath;
        if(link.toLowerCase() === 'privacy notice') {
            launchUrl = 'https://www.pearson.com/en-gb/privacy-center/privacy-notices.html';
        } else if(link.toLowerCase() === 'cookie policy') {
            launchUrl = 'https://www.pearson.com/en-gb/legal-information/cookie-policy.html';
        } else if(link.toLowerCase() === 'accessibility') {
            launchUrl = 'https://www.pearson.com/en-gb/legal-information/accessibility.html';
        }

        return CommonUtils.launchWindowByURL(launchUrl, launchType);
    }

    return (
        <div className="gwFooter">
            <div className="container-fluid">
                <div className="position-relative d-md-flex">
                    <div className="gwFooter__links">
                        <ul className="gwFooter__links__ul">
                            { props.footerLinks.map((footer, index) => {
                                    return (
                                        <li className="gwFooter__links__li" key={index}>
                                            <span
                                                href="#"
                                                title={footer.description}
                                                tabIndex='0' role='link'
                                                onClick={() => footerLinksURL(footer.name, footer.launchUrl, footer.launchType)}
                                                onKeyDown={(e) =>{
                                                    console.log(e.key)
                                                    if(e.key==='Enter'||e.key===' ') footerLinksURL(footer.name, footer.launchUrl, footer.launchType)
                                                }}
                                            >
                                                {footer.name}
                                                <span class="screen-reader-only">Opens in a new window</span>
                                            </span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="gwFooter__copy">{entities.decode(props.copyRight)}</div>
                    {/* {
                        !isActiveHub&&
                        <div className={`gwFooter__access ${isActiveHub && 'gwFooter__access--ahub'}`} >
                        <button
                            className="gwbutton gwaccess__link"
                            placeholder=""
                            aria-label="Accessibility"
                            tabIndex=""
                            title="Accessibility"
                            onClick={handleAccessibilityClick}
                        >
                            A
                        </button>
                        {
                            props.children
                        }
                    </div>
                    } */}
                </div>
            </div>
        </div>
    )
}

Footer.propTypes = {
    footerLinks: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            footerlinkId: PropTypes.string,
            launchType: PropTypes.string,
            launchUrl: PropTypes.string,
            location: PropTypes.string,
            name: PropTypes.string
        })
    ).isRequired,
    copyRight: PropTypes.string,
    toggleAccessibilityDialog: PropTypes.func.isRequired,
    isAccesibilityPopupOpen: PropTypes.bool.isRequired
};
