import React, { useContext, useState, useMemo, useCallback } from "react";
import { TabHeader, CollapsibleSideBarMenu, Button } from 'common-components-spa';
import { AppContext } from "src/App";
import { itemsList } from '../../constants/accessCodeMenuItem';
import RedeemAccessCode from '../redeemAccessCode/redeemAccessCode'

// User Routes
import { useLocation } from 'react-router-dom';
import './acessCodeWrapper.scss'

const AccessCodeWrapper = () => {
  // getting values from context
  const { translate: t, errorPopup } = useContext(AppContext);

  //confirmation popup
  const [collapsed, setCollapsed] = useState(true);
  const { pathname } = useLocation();
  const renderExtraElement = useCallback(() => {
    return <div className={`accessCode_popup_footer justify-content-center p-1 mt-4 `}>
      <Button className={`button__v2 ripple--v2 modalbtn button__v2__primary m-0 -loginbtn mb-3  button__v2__bordered mr-3 `} useButtonClass={false} onClick={() => { errorPopup.setVisibility(false) }}>
        { t(`Okay, Got it`)}
      </Button>
    </div>
  }, [])
  const showErrorPopup = () => {
    errorPopup.setState({
      info: t(`An email Access Code is 16 characters long (excluding hyphens) and will usually contain both letters and numbers.`),
      subInfo: t(`for example (A23C- BE34- 1QZ4-12QW).`),
      image: `${process.env.PUBLIC_URL}/images/auth.svg`,
      subInfoClassName: 'infomodal__subinfo d-block  text-left',
      infoClassName: 'infomodal__info  text-left',
      onClosePopup: () => { errorPopup.setVisibility(false) },
      cancelButtonAction: () => { },
      renderExtraElement: renderExtraElement
    });
    errorPopup.setVisibility(true);
  };
  const activeTab = useMemo(() => {
    const foundItem = itemsList.find(item => {
      if (item.exact) return item.path === pathname;
      else return pathname.includes(item.path);
    });
    return foundItem && foundItem.id;
  }, [pathname]);

  function collapse() {
    setCollapsed(!collapsed)
  }

  return (
    <div className="d-flex accesscode-container">
      
      <div className={`ogaWrapper ${collapsed ? "ogaWrapper__collapsed" : " "}`}>
        <div className="position-relative w-100 header-container">
          <TabHeader
            title={t(`Access Code Redemption`)}
            description={t(`Redeem your access code to enable the course.`)}
            helpClick={(e) => (console.log('clicked'))}
            isCollapsed={collapsed}
          >
          </TabHeader>
        </div>
        <div className="ogaWrapper__bdy mx-1">
          <div className="col-6 mx-auto px-0 mt-3">
            <div className="instructor">
              <div className="instructor__head">
                <div className="d-flex align-items-center">
                  <div className="col-12 p-0">
                    <h3 className="instructor__head__txt -textstylechange ">{t(`Redeem Access Code`)}</h3>
                  </div>
                </div>
              </div>
              <div className="instructor__body">
                <RedeemAccessCode
                  showErrorPopup={showErrorPopup}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessCodeWrapper;
