import React, { useContext } from 'react';
import {useHistory} from "react-router-dom"
import './index.css';
import './PreLogin.scss';
import { useAction } from './useAction'
import { AuthenticationContext } from 'src/BaseApp';

const landingspadetails = {
    name: "Landing SPA",
    description: "View Landing SPA",
    launchPath: "/spa/landing",
    styleSheetElementBaseName: "Landing",
    spaId: "urn:pearson:gps:spa:landingspaid"
  };


const PreLogin = () => {
    const history = useHistory();
    const {isTokenExist} = useContext(AuthenticationContext)

    const actionObject = useAction();

    const redirectToApplication = () => {
            history.push(`/application/${landingspadetails.spaId}?`)
     }

    if (isTokenExist) {
        redirectToApplication();
    }

    return (
        <div className="d-lg-flex d-md-flex creation">
            <div className="col-12 col-md-6 col-lg-6 p-0">
                <div className="creation__wrapleft">
                    <div className="creation__logo">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/Pearson-logo-desktop.svg`}
                            alt="Pearson"
                            className="d-none d-md-block"
                        />
                        <img
                            src={`${process.env.PUBLIC_URL}/images/Pearson-logo-mobile.svg`}
                            alt="Pearson"
                            className="d-block d-md-none"
                        />
                    </div>
                    <div className="creation__banner">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/Pictogram-RGB-Blue_Account login.svg`}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 p-0 col-right">
                <div className="creation__wrap">
                    <h3 className="creation__wrap__head mb-4">
                        Welcome to Pearson Gateway, the portal that 
                        gives you access to ActiveHub, ActiveLearn & 
                        Revise, and enables you to manage all Admin 
                        settings for ActiveHub
                    </h3>
                    {/* <button className="button--v2 button--v2__bordered create-btn" onClick={() => actionObject.handleRedirectToWrapper('gateway')}>
                        Login with Pearson Gateway
                    </button>
                    <button className="button--v2 button--v2__bordered create-btn" onClick={() => actionObject.handleRedirectToWrapper('ALPrimary')}>
                        Login with ActiveLearn Primary
                    </button>
                    <button className="button--v2 button--v2__bordered create-btn" onClick={() => actionObject.handleRedirectToWrapper('ALSecondary')}>
                        Login with ActiveLearn Secondary
                    </button> */}
                    <button className="button--v2 button--v2__bordered create-btn m-0" onClick={() => actionObject.handleRedirectToWrapper('gateway')}>
                        Sign in to Pearson Gateway
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PreLogin;