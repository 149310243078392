import React from "react";
import "./AppOutage.scss";

function AppOutage() {
  return (
    <>
      <div className="ogaWrapper ogaWrapper--ahub spalsh-body">
        <div className="ogaWrapper__bdy -acchead px-0">
          <div className="gsam-card -access-card -accbody">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <img
                    className="spalshimg w-100"
                    src={`${process.env.PUBLIC_URL}/images/app_outage.svg`}
                    alt="We’ll be right back!"
                  />
                </div>
                <h1 className="gsam-card__head__title -spalsh-black-bold-xxl mb-4 pb-2 text-center">
                  We’ll be right back!
                </h1>
                <h2 className="gsam-card__head__title -spalsh-black-reg-lg text-center">
                  Please try after some time
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppOutage;
