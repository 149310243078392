import React, { useContext, useMemo, useState, useCallback, useEffect } from 'react';
import './SpaWrapper.scss';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { AppContext } from '../../App';
import MicroFrontend from '../../components/micro-frontend/microfrontend';
import { Can, ErrorPopUp, Loader, NavigationHeader } from 'common-components-spa';
import { serviceError } from '../../constants/config'
import { useMountEffect, useUpdateEffect } from '../../utils/CustomHooks';
import { Routes } from '../../components';
import UserWrapper from '../UserWrapper/UserWrapper';
import AccessCodeWrapper from '../acessCodeWrapper/accessCodeWrapper';

export function SpaWrapper() {
    const { params: { applicationId: encodedApplicationId }, path: matchPath, url: matchURL } = useRouteMatch();
    const history = useHistory();
    const [showMsg, setShowMsg] = useState(false);
    const [errorState, setErrorState] = useState({ heading: '', info: '' });
    const {
        entities, spaDetailsList: spaConfig, defaultSpaId: landingPageId,
        selectedSpaId, setSelectedSpaId, iesSession,
        iframeLevelErrorPage, setIframeLevelErrorPage, activeHubInfo, userId,
        onRouteFromUnsavedFormHOF
    } = useContext(AppContext);
    const { isActiveHub } = activeHubInfo

    const [spaState, setSPAState] = useState({
        containerId: '',
        name: '',
        host: ''
    });
    const [mfState, setMfState] = useState(false);
    const applicationId = decodeURIComponent(encodedApplicationId);

    const updateSpaState = (id) => {
        const spa = spaConfig?.find(spa => spa.spaId === id);
        
        setSPAState({
            containerId: spa?.spaId,
            name: spa?.name,
            host: spa?.launchPath
        });
        setMfState(true);
    };

    const location = useLocation()

    /**
     * This case handles when there are no SPAs present in the region.
     * Default to Landing Page
     */
    useMountEffect(() => {
        const foundSpa = applicationId && spaConfig?.find(spa => spa.spaId === applicationId);
        if (selectedSpaId === serviceError) {
            errorInterceptor();
        } else if (!foundSpa && spaConfig?.length > 0 && applicationId !== "preferences" && applicationId !== "userProfile" && applicationId !== "updatePassword" && applicationId !== "redeemcode") {
            const landingPageExists = landingPageId && spaConfig.find(spa => spa.spaId === landingPageId);
            const defaultSpaId = landingPageExists ? landingPageId : spaConfig[0].spaId;
            const newEncodedApplicationId = encodeURIComponent(defaultSpaId);
            setSelectedSpaId(defaultSpaId);
            setIframeLevelErrorPage(null);
            const newUrl = matchPath.replace(":applicationId?", newEncodedApplicationId);
            history.push(newUrl);
        } else {
            /**
             * If the applicationId from URL is not same as selected SPA id,
             * set the selected SPA id same as application id.
             * This is used to handle reload state, when selectedSpaId is reset to default.
             */
            setSelectedSpaId(applicationId);
            updateSpaState(applicationId);
        }
    });

    useUpdateEffect(() => {
        // console.log('Application ID changed', selectedSpaId);
        // setMfState(false);
        // setIframeLevelErrorPage(null);
        // const newEncodedApplicationId = encodeURIComponent(selectedSpaId);
        // const newUrl = matchPath.replace(':applicationId?', newEncodedApplicationId);
        // history.push(newUrl);
        // updateSpaState(selectedSpaId);
        console.log('applicationId = ', applicationId);
        console.log('selectedSpaId = ', selectedSpaId);
        if (applicationId !== selectedSpaId || selectedSpaId === landingPageId) {
            console.log(`Application ID changed from ${applicationId} to ${selectedSpaId}`);
            setMfState(false);
            const newEncodedApplicationId = encodeURIComponent(selectedSpaId);
            const newUrl = matchPath.replace(':applicationId?', newEncodedApplicationId);
            history.push(newUrl);
            updateSpaState(selectedSpaId);
        }
    }, [selectedSpaId]);


    function changeApplication(spaId) {
        onRouteFromUnsavedFormHOF(()=>setSelectedSpaId(spaId));
    }

    function closeErrorPopup() {
        setShowMsg(false)
        // handleCancel()
    }

    const headerTabs = useMemo(() => {
        if (isActiveHub) {
            return spaConfig.reverse().map(it => ({
                title: it.name === "Landing SPA" ? 'Dashboard' : entities.decode(entities.decode(it.name)),
                id: it.spaId
            }));
        }
        else {
            return spaConfig?.filter(item => item.spaId !== landingPageId).map(it => ({
                title: it.name,
                id: it.spaId
            }));
        }

    }, [spaConfig]);


    const errorInterceptor = useCallback((error) => {
        if (error.response.status === 401) {
            iesSession.logout();
        }
        if (error.response.status >= 500) {
            // setSelectedSpaId(serviceError)
            // history.replace(`/service-error?error=true&source=${encodeURIComponent(window.location.href)}`);
            // TODO: Show an API failure dialog instead of redirecting to service error page
            console.error('INTERNAL SERVER ERROR', error);
            if (error.response.status >= 500) {
                const permissionErrObj = {
                    heading: `${error.response.status} - ${error.response.statusText}`,
                    info: error.response.data.message,
                    subInfo: '',
                    btnName: 'Try now',
                    buttonAction: closeErrorPopup,
                    cancelBtnName: 'Try Later',
                    cancelButtonAction: () => setShowMsg(false),
                    cancelBtnStyle: false,
                    btnStyle: 'modalbtn',
                    image: `${process.env.PUBLIC_URL}/images/api-failure.svg`,
                    onClosePopup: closeErrorPopup
                };

                setErrorState(permissionErrObj);

                // setErrorState({
                //   heading: `${error.response.status} - ${error.response.statusText}`,
                //   info: error.response.data.message
                // })
                setShowMsg(true)
            }
        }
        if (error.response.status === 403) {
            const permissionErrObj = {
                heading: 'Sorry!!',
                info: 'You are not entitled for this action.',
                subInfo: 'Please contact Support/Administrator.',
                btnName: 'Okay, Got it',
                buttonAction: closeErrorPopup,
                cancelBtnName: '',
                cancelButtonAction: () => {
                },
                cancelBtnStyle: '',
                btnStyle: true,
                image: `${process.env.PUBLIC_URL}/images/error-sorry.svg`,
                onClosePopup: closeErrorPopup
            };

            setErrorState(permissionErrObj);

            // setErrorState({
            //   heading: `${error.response.status} - ${error.response.statusText}`,
            //   info: error.response.data.message
            // })
            setShowMsg(true)
        }
    }, [setShowMsg, setErrorState, iesSession]);

    window.GATEWAY = Object.assign(window.GATEWAY || {}, {
        containerId: applicationId,
        baseName: matchURL,
        errorInterceptor: errorInterceptor
    });

    const microFrontend = useCallback(() => {
        if (spaState.containerId && spaState.host && mfState) {
            return iframeLevelErrorPage ||
                <MicroFrontend {...spaState}
                    errorInterceptor={errorInterceptor}
                    setIframeLevelErrorPage={setIframeLevelErrorPage} />
        } else {
            return <Loader />
        }
    }, [spaState, mfState, iframeLevelErrorPage, setIframeLevelErrorPage, errorInterceptor])

    const routes = useMemo(() => [
        {
            component: UserWrapper,
            path: "/application/preferences",
            exact: false,
            title: "Global Gateway Wrapper",
        },
        {
            component: UserWrapper,
            path: "/application/userProfile",
            exact: false,
            title: "Global Gateway Wrapper",
        },
        {
            component: UserWrapper,
            path: "/application/updatePassword",
            exact: false,
            title: "Global Gateway Wrapper",
        },
        {
            component: AccessCodeWrapper,
            path: "/application/redeemcode",
            exact: true,
            title: "Global Gateway Wrapper",
        },
        {
            component: () => (microFrontend()),
            path: "/application/:applicationId?",
            exact: false,
            title: "Global Gateway Wrapper",
        }

    ], [microFrontend])

    useEffect(() => {
        window.Appcues && window.Appcues.page();
    }, [location])

    useEffect(() => {
        window.Appcues && window.Appcues.identify(userId);
    }, [])

    return (
        <div className="gwSpaWrapper">
            <ErrorPopUp
                isOpen={showMsg}
                heading={errorState.heading}
                info={errorState.info}
                btnName={errorState.btnName}
                buttonAction={errorState.buttonAction}
                btnStyle={errorState.btnStyle}
                cancelBtnName={errorState.cancelBtnName}
                cancelButtonAction={errorState.cancelButtonAction}
                cancelBtnStyle={errorState.cancelBtnStyle}
                image={errorState.image}
                closePopup={errorState.onClosePopup}
                subInfo={errorState.subInfo}
            />
            <div className={`gwSpaWrapper__tabs ${isActiveHub && 'gwSpaWrapper__tabs--ahub'}`}>
                <Can I="READ" a="GATEWAY_CONFIGURATION_SPA">
                    <NavigationHeader
                        tabs={headerTabs}
                        onSelect={changeApplication}
                        activeTabId={spaState.containerId}
                        needCarousal={true}
                    />
                </Can>
            </div>

            <div className={`gwSpaWrapper__iframe ${isActiveHub && 'gwSpaWrapper__iframe--ahub'}`}>
                <Routes routes={routes} defaultRoute={routes[3].path} />
            </div>
        </div>
    );
}
