import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/App';
import { LoadingPageView } from 'src/components';
import { App } from 'src/page';
import { AbilityContext } from 'common-components-spa';

export function Landing() {
  const [isApiDone, setApiDone] = useState(false);
  const [isLoadingDone, setLoadingDone] = useState(false);
  const appContext = useContext(AppContext);
  const { spaDetailsList, region, fetchSpasInRegion } = appContext;
  const ability = useContext(AbilityContext);


  useEffect(() => {
    setLoadingDone(false);
    setApiDone(false);
    const subscription = fetchSpasInRegion(ability);

    return () => subscription.unsubscribe();
    // dont need to call api again if setter functions change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  useEffect(() => {
    if (spaDetailsList) {
      setApiDone(true);
    }
  }, [spaDetailsList]);

  function onProgressBarFilled() {
    setLoadingDone(true);
  }

  if (!isLoadingDone) {
    return (
      <LoadingPageView
        done={isApiDone} onProgressBarFilled={onProgressBarFilled}
        animationDuration={1}
        mainClass="gwProgressbar"
      />
    );
  }

  return <App />;
}
